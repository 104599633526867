import React from "react"
import Layout from "../components/Layout"
import "../css/main.css"
import "../css/contact.css"
import Particle2 from "../components/particle2"
import SEO from "../components/SEO"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Contact"
        description="If you want to tell something, you are at the right place. This page will direct you to get contact with Arpit Somani"
      />
      <Particle2></Particle2>
      <h6>s</h6>
      <div className="contact-page">
        <h1>Contact</h1>
      </div>
      <section className="contact-forms noSelect">
        <h3>Nowdays, its very easy to reach out to a person.</h3>
        <br />
        <p>
          Well, let me tell you a very intresting thing, many will come and go
          on this website, yet very few people can manage to contact because may
          be there's no connection.
          <br />
          <br />
          If you have a message for me, it's great and proceed with the contact
          form.
          <br />
          Those who want to send me a message, but still figuring out - about
          what ? <br />
          Here goes a hint...
          <br />
          <br />
          Tell me about what you like the most in my website. Let me give you a
          hint, it may be any content, any intresting project, any blog post, or
          any other part.
          <br />
          <br />
          Ok... let me tell you first what i like the most in my website. Well,
          i built it and i liked it all. Still there's one very intresting
          thing, which i like the most in my website is it's background. As you
          can see it's not any ordinary/normal background, even it's not any
          image, gif, video or any other media. It is a complete magic of
          coding, done through <mark>ParticleJS</mark>.<br />
          <br />
          You can also get connect with me on social media, you will find links
          down there.
        </p>
        <br />

        <h3>Well here goes a simple way to callout at me.</h3>
      </section>
      <section className="contact-f noSelect">
        <article className="contact-form">
          <h3>Get in Touch</h3>
          <form action="https://formspree.io/xqkywkyw" method="POST">
            <div className="form-group">
              Name:
              <input
                type="text"
                name="name"
                placeholder="arpit somani"
                className="form-control na"
                required
                pattern="\S+.*"
                aria-required="true"
              />
              Email:
              <input
                type="email"
                placeholder="arpit.somanias8@gmail.com"
                name="email"
                className="form-control"
                required
                pattern="\S+.*"
                aria-required="true"
              />
              Topic:
              <input
                type="text"
                name="Topic"
                placeholder="Regarding which topic/subject"
                className="form-control"
                required
                pattern="\S+.*"
                aria-required="true"
              />
              Message:
              <textarea
                name="message"
                rows="5"
                placeholder="Type here your message..."
                className="form-control"
                pattern="\S+.*"
                required
              ></textarea>
            </div>
            <button type="submit" className="bton">
              Send
            </button>
          </form>
        </article>
      </section>
    </Layout>
  )
}

export default contact

import React from "react"
import Particles from "react-particles-js"
import "../css/main.css"

const paticle2 = () => {
  return (
    <div id="particles-js">
      <Particles
        params={{
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 1200,
              },
            },
            color: {
              value: "#30b0e3",
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#30b0e3",
              },
              polygon: {
                nb_sides: 3,
              },
            },
            opacity: {
              value: 0.4,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 15,
              random: true,
              anim: {
                enable: false,
                speed: 0,
                size_min: 0,
                sync: false,
              },
            },
            line_linked: {
              enable: false,
              distance: 220,
              color: "#c72ed1",
              opacity: 1,
              width: 0,
            },
            move: {
              enable: true,
              speed: 2,
              direction: "bottom",
              random: true,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: true,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
          interactivity: {
            detect_on: "window",
            events: {
              onhover: {
                enable: false,
                mode: "bubble",
              },
              onclick: {
                enable: false,
                mode: "bubble",
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 1,
                },
              },
              bubble: {
                distance: 300,
                size: 15,
                duration: 2,
                opacity: 0.43,
                speed: 2,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
              push: {
                particles_nb: 4,
              },
              remove: {
                particles_nb: 2,
              },
            },
          },
          retina_detect: true,
        }}
      />
    </div>
  )
}

export default paticle2
